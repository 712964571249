<template>
	<div>
		<confirm ref="confirm"></confirm>
		<div>
			<vue-headful v-if="offerDetails" :title="offerDetails.Title" />
			<v-breadcrumbs :items="breadcrumbsItems">
				<template v-slot:divider>
					<v-icon class="secondary--text text--lighten-2"
						>fas fa-caret-right</v-icon
					>
				</template>
			</v-breadcrumbs>

			<v-alert text type="info" dismissible v-if="offerDetails">
				<span class="font-weight-bold">{{ $t("titleSteps") }}</span>
				<br />
				<template>
					{{ $t("offerStep1") }}
					<br />
					{{ $t("offerStep2") }}
					<br />
					{{ $t("offerStep3") }}
				</template>
			</v-alert>
			<v-progress-linear
				indeterminate
				v-if="LoadingOfferDetails"
			></v-progress-linear>
			<v-card class="my-2 pa-2" v-if="offerDetails">
				<v-alert
					v-if="offerDetails.StatusId === SgpOfferStatusIdEnum.REVOKED"
					type="warning"
				>
					{{ $t("revokedOffer") }}
				</v-alert>

				<v-card-text v-else>
					<div class="text-h5">{{ offerDetails.Title }}</div>
					<div class="mb-2">
						<span class="text-overline"> {{ $t("sgp.offer") }}</span>
						| | {{ $t("offerReceived") }}
						{{ offerDetails.CreateDate | formatDate }}
					</div>
					<PhotoGrid
						:Files="offerDetails.SGPOfferItems"
						class="mt-5"
						:targetHeight="$vuetify.breakpoint.xs ? 140 : 200"
					>
						<template v-slot:cell="{ file, index }">
							<SelectableItem v-model="file.Selected">
								<OfferImage
									v-if="file.MediaTypeId === 1"
									@click="ShowGalleryDialog(index)"
									:file="file"
									:addSasToken="false"
								>
									<div class="ArtistInfo" v-if="file.SgpApprovalOfferItem">
										<ApproveStatusIcon
											:size="18"
											:approve-status="file.SgpApprovalOfferItem.ApprovalStatus"
										></ApproveStatusIcon>
										<!--										{{ file.SgpApprovalOfferItem.ApprovalStatus }}-->
										{{ file.SgpApprovalOfferItem.RetouchingNotes }}
									</div>
								</OfferImage>
								<VimeoThumbnail
									v-else
									@click="ShowGalleryDialog(index)"
									:src="file.ExternalPreviewUrl"
									:alt="file.FileName"
								>
								</VimeoThumbnail>
							</SelectableItem>
						</template>
					</PhotoGrid>

					<OfferGalleryDialog
						:Files="offerDetails.SGPOfferItems"
						:OfferKey="OfferKey"
						v-model="ShowGallery"
						v-bind:carouselCurrentIndex="carouselCurrentIndex"
						@carousel-current-index-changed="OnCarouselIndexChanged($event)"
						@approveStatusUpdated="loadOfferDetails"
					></OfferGalleryDialog>

					<div class="my-5 text-center" v-if="offerDetails">
						<v-btn
							class="green white--text mb-3"
							large
							:disabled="!offerDetails.SGPOfferItems.find((i) => i.Selected)"
							@click="OnAcceptClick"
							:loading="sendingRequest"
						>
							<v-icon left>fas fa-check</v-icon>
							{{ "Approve" }}
						</v-btn>
						<v-btn
							class="error white--text ml-2 mb-3"
							large
							:disabled="!offerDetails.SGPOfferItems.find((i) => i.Selected)"
							@click="OnRejectClick"
							:loading="sendingRequest"
						>
							<v-icon left>fas fa-times</v-icon>
							{{ "Reject" }}
						</v-btn>
						<v-btn
							class="orange accent-3 white--text ml-2 mb-3"
							large
							:disabled="!offerDetails.SGPOfferItems.find((i) => i.Selected)"
							@click="ShowRetouchingNotes"
							:loading="sendingRequest"
						>
							<v-icon left>fas fa-edit</v-icon>
							{{ "Request Retouching" }}
						</v-btn>
						<v-dialog max-width="500" v-model="showRetouchingDialog">
							<v-card>
								<v-card-title> Please describe </v-card-title>
								<v-card-text>
									<v-textarea
										v-model="retouchingNotes"
										label="Please describe the requested retouching"
									></v-textarea>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										@click="showRetouchingDialog = false"
										color="primary"
										text
										>{{ $t("common.cancel") }}</v-btn
									>
									<v-btn
										@click="SendRetouchingRequest"
										class="orange accent-3 ml-2"
										dark
									>
										<v-icon left>fas fa-edit</v-icon>
										{{ "Request Retouching" }}</v-btn
									>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</div>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

import OfferGalleryDialog from "./ApproveOfferGalleryDialog";
import SelectableItem from "@/components/Shared/Common/SelectableItem";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail";
import OfferImage from "@/components/Shared/UI/OfferImage";
import Confirm from "@/components/Shared/Common/Confirm";

import ApproveStatusIcon from "./ApproveStatusIcon";

import { SgpOfferTypeEnum } from "../Events/components/sgpOfferTypesEnum.js";
import { SgpOfferStatusIdEnum } from "../Events/components/sgpOfferStatus";

export default {
	components: {
		OfferGalleryDialog,
		SelectableItem,
		PhotoGrid,
		VimeoThumbnail,
		OfferImage,
		Confirm,
		ApproveStatusIcon,
	},
	data() {
		return {
			offerDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],

			LoadingOfferDetails: false,
			showPhotoGrid: false,

			ShowGallery: false,
			carouselCurrentIndex: 0,

			sendingRequest: false,
			showRetouchingDialog: false,
			retouchingNotes: null,
		};
	},

	computed: {
		OfferKey() {
			return this.$route.params.offerKey;
		},
		SelectedItems() {
			return this.offerDetails?.SGPOfferItems.filter((i) => i.Selected);
		},
		isVideoOrder() {
			if (this.offerDetails.length === 0) return false;

			return this.offerDetails.SGPOfferItems[0].MediaTypeId === 2;
		},
	},
	watch: {},
	created() {
		this.OffersService = new CrudClient("SGP/ApproverOffers");
		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
		this.SgpOfferStatusIdEnum = SgpOfferStatusIdEnum;
	},
	async mounted() {
		await this.loadOfferDetails();

		this.breadcrumbsItems.push({
			text: this.$t("sgp.offers"),
			disabled: false,
			exact: true,
			to: "/Clients/Offers",
		});

		// this.breadcrumbsItems.push({
		// 	text: this.offerDetails.Title,
		// 	disabled: true,
		// 	exact: true,
		// 	to: "/Clients/Offers/" + this.OfferId,
		// });
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		ShowGalleryDialog(index) {
			this.ShowGallery = true;
			this.carouselCurrentIndex = index;
		},
		OnCarouselIndexChanged(index) {
			this.carouselCurrentIndex = index;
		},

		async loadOfferDetails() {
			try {
				this.LoadingOfferDetails = true;
				this.offerDetails = await this.OffersService.GetSelectedFields(
					this.OfferKey,
					"*, SGPOfferStatus.*,SGPOfferItems.*, SGPOfferItems.SgpApprovalOfferItem.*"
				);

				// Order Items by file name
				this.offerDetails.SGPOfferItems = this.offerDetails.SGPOfferItems.sort(
					(a, b) =>
						a.FileName > b.FileName ? 1 : b.FileName > a.FileName ? -1 : 0
				);

				this.$log.debug("SGPOfferItems sorted");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });

				return;
			} finally {
				this.LoadingOfferDetails = false;
			}

			this.offerDetails.SGPOfferItems.forEach((oi) => {
				this.$set(oi, "Selected", false);
				this.$set(oi, "approvalStatus", null);
				this.$set(oi, "retouchingNotes", null);
			});
		},

		async OnAcceptClick() {
			this.sendingRequest = true;

			try {
				for (const o of this.SelectedItems) {
					await this.OffersService.Post(
						this.OfferKey + "/" + o.MediaId,
						null,
						"Approve",
						false
					);
				}
				const itemCount = this.SelectedItems.length;
				this.snackSuccess({
					Text: this.$tc("successAccept", itemCount, { count: itemCount }),
				});
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			} finally {
				this.sendingRequest = false;
			}

			await this.loadOfferDetails();
		},

		async OnRejectClick() {
			this.sendingRequest = true;

			try {
				for (const o of this.SelectedItems) {
					await this.OffersService.Post(
						this.OfferKey + "/" + o.MediaId,
						null,
						"Reject",
						false
					);
				}
				const itemCount = this.SelectedItems.length;
				this.snackSuccess({
					Text: this.$tc("successReject", itemCount, { count: itemCount }),
				});
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			} finally {
				this.sendingRequest = false;
			}

			await this.loadOfferDetails();
		},

		ShowRetouchingNotes() {
			this.showRetouchingDialog = true;
			this.retouchingNotes = null;
		},
		async SendRetouchingRequest() {
			if (!this.retouchingNotes || this.retouchingNotes.trim() === "") {
				this.snackError({ Text: "Please describe the modifications" });
				return;
			}
			this.sendingRequest = true;

			try {
				for (const o of this.SelectedItems) {
					await this.OffersService.Post(
						this.OfferKey + "/" + o.MediaId,
						{ Notes: this.retouchingNotes },
						"RequestRetouching",
						false
					);
				}
				const itemCount = this.SelectedItems.length;
				this.snackSuccess({
					Text: this.$tc("successRetouchingRequest", itemCount, {
						count: itemCount,
					}),
				});
				this.showRetouchingDialog = false;
				this.retouchingNotes = null;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			} finally {
				this.sendingRequest = false;
			}

			await this.loadOfferDetails();
		},
	},
};
</script>
<style lang="scss" scoped>
.darkened-image {
	filter: brightness(100%);
	transition: 0.3s;
	&:hover {
		filter: brightness(80%);
	}
}

.ArtistInfo {
	position: absolute;
	bottom: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.8;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}
</style>
<i18n>
{
	"it": {
		"pageTitle": "Dettaglio Proposta",
		"termsError": "Devi accettare i termini e le condizioni",
		"titleSteps": "Come funziona?",

		"offerStep1": "1. Visualizza l'anteprima cliccando sull'immagine",
		"offerStep2": "2. Seleziona le immagini che vuoi acquistare",
		"offerStep3": "3. Procedi all'ordine",

		"revokedOffer": "La proposta non è più attiva.",
		"offerReceived": "Ricevuto il ",
		"proceedToOrder": "Procedi all'ordine",
		"proceed": "Procedi",
		"successAccept":"Hai approvato {count} foto | Hai approvato {count} foto",
		"successReject": "Hai rifiutato {count} foto | Hai rifiutato {count} foto",
		"successRetouchingRequest": "Retouching request for {count} photo is sent | Retouching request for {count} photos is sent"
	},
	"en": {
		"pageTitle": "Offer Details",
		"titleSteps": "How does it work?",
		"offerStep1": "1. Preview by clicking on the image",
		"offerStep2": "2. Select the images you want to buy",
		"offerStep3": "3. Proceed to order",
		"revokedOffer": "The offer is no longer active.",
		"offerReceived": "Received on ",
		"proceedToOrder": "Proceed to order",
		"proceed": "Proceed",
		"successAccept":"You have approved {count} photo | You have approved {count} photos",
		"successReject": "You have rejected {count} photo | You have rejected {count} photos",
		"successRetouchingRequest": "Retouching request for {count} photo is sent | Retouching request for {count} photos is sent"
	}
}
</i18n>
