<template>
	<div>
		<h1 class="text-h2">{{ $t("support") }}</h1>
		<div class="mx-5">
			<div class="text-h6 ma-3 text-left">
				{{ $t("helpText") }}
			</div>
			<div class="ma-3">
				<span class="primary--text font-weight-bold">{{ $t("common.email") }}:</span>
				produzione@sgpitalia.com
				<br />
				<span class="primary--text font-weight-bold"> {{ $t("common.phone") }}:</span> +39 02
				20480364
				<br />
				<span class="primary--text font-weight-bold">{{ $t("emergency") }} </span> +39 393
				9703008
			</div>

			<div class="mt-8 grey--text text--lighten-1" style="font-size: 12px">
				Run number: {{ VUE_APP_RUN_NUMBER }} Run Id: {{ VUE_APP_RUN_ID }}<br />
				{{ VUE_APP_PAYPAL_ENV }} {{ VUE_APP_PAYPAL_SANDBOX_CLIENTID }}
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			VUE_APP_RUN_NUMBER: process.env.VUE_APP_RUN_NUMBER,
			VUE_APP_RUN_ID: process.env.VUE_APP_RUN_ID,
			VUE_APP_PAYPAL_ENV: process.env.VUE_APP_PAYPAL_ENV,
			VUE_APP_PAYPAL_SANDBOX_CLIENTID: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENTID,
		};
	},
};
</script>
<i18n>
{
	"it":{
		"support": "Assistenza",
		"helpText": "Per richieste di assistenza contattare il nostro ufficio ai seguenti recapiti:",
		"emergency": "Per urgenze"
	},
	"en": {
		"support": "Support",
		"helpText": "For assistance requests, please contact our office at the following contact details:",
		"emergency": "Emergency"
	}
}
</i18n>
