<template>
	<v-dialog
		fullscreen
		hide-overlay
		:value="value"
		@input="$emit('input', $event)"
		@keydown.esc="HideImageDialog"
		:scrollable="false"
	>
		<div class="containerF black fill-height">
			<v-toolbar class="div1" dense flat v-if="carouselCurrentIndex >= 0">
				<v-btn icon @click="HideImageDialog">
					<v-icon>fas fa-arrow-left</v-icon>
				</v-btn>

				<v-toolbar-title>
					<ApproveStatusIcon
						:size="24"
						class="mr-3"
						:approve-status="
							Files[carouselCurrentIndex].SgpApprovalOfferItem.ApprovalStatus
						"
					></ApproveStatusIcon>
					{{
						GetApprovalStatusText(
							Files[carouselCurrentIndex].SgpApprovalOfferItem.ApprovalStatus
						)
					}}
				</v-toolbar-title>

				<v-spacer></v-spacer>
			</v-toolbar>

			<v-card
				class="div2 grey lighten-3 rounded-0"
				ref="actionsCardSection"
				v-resize="CalculateActionsCardSectionHeight"
			>
				<v-card-text>
					<v-alert
						icon="fas fa-edit"
						color="yellow accent-1"
						style="max-width: 600px; margin-left: auto; margin-right: auto"
						v-if="
							Files[carouselCurrentIndex].SgpApprovalOfferItem.RetouchingNotes
						"
					>
						{{
							Files[carouselCurrentIndex].SgpApprovalOfferItem.RetouchingNotes
						}}
					</v-alert>
					<div class="text-center">
						<v-btn
							class="green mb-2"
							dark
							@click="OnAcceptClick(Files[carouselCurrentIndex])"
							:class="{
								'lighten-3':
									Files[carouselCurrentIndex].SgpApprovalOfferItem
										.ApprovalStatus === SgpApprovalOfferItemStatusEnum.APPROVED,
							}"
							:loading="sendingRequest"
						>
							<v-icon left>fas fa-check</v-icon>
							{{ $t("Approve") }}
						</v-btn>
						<v-btn
							class="error ml-2 mb-2"
							@click="OnRejectClick(Files[carouselCurrentIndex])"
							:loading="sendingRequest"
							:class="{
								'lighten-3':
									Files[carouselCurrentIndex].SgpApprovalOfferItem
										.ApprovalStatus === SgpApprovalOfferItemStatusEnum.REJECTED,
							}"
						>
							<v-icon left>fas fa-times</v-icon>
							{{ $t("Reject") }}
							<!--							"Approve": "Approva",-->
							<!--							"Reject": "Rifiuta",-->
							<!--							"RequestRetouching": "Richiedi ritocco"-->
						</v-btn>
						<v-btn
							class="orange accent-3 ml-2 mb-2"
							dark
							@click="ShowRetouchingNotes(Files[carouselCurrentIndex])"
							:loading="sendingRequest"
						>
							<v-icon left>fas fa-edit</v-icon>
							{{ $t("RequestRetouching") }}
						</v-btn>
					</div>
					<v-dialog max-width="500" v-model="showRetouchingDialog">
						<v-card>
							<v-card-title> Please describe </v-card-title>
							<v-card-text>
								<v-textarea
									v-model="retouchingNotes"
									label="TODO: Please describe the editing "
								></v-textarea>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									@click="showRetouchingDialog = false"
									color="primary"
									text
									>{{ $t("common.cancel") }}</v-btn
								>
								<v-btn
									@click="SendRetouchingRequest(Files[carouselCurrentIndex])"
									class="orange accent-3 ml-2"
									dark
								>
									<v-icon left>fas fa-edit</v-icon>
									{{ $t("RequestRetouching") }}</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-card-text>
			</v-card>

			<v-container fill-height fluid class="black div3">
				<v-row align="center" justify="center">
					<v-col class="pa-0" cols="12">
						<v-carousel
							height="auto"
							v-bind:value="carouselCurrentIndex"
							@change="OnCarouselCurrentIndex"
							:continuous="false"
							:hide-delimiters="true"
							prev-icon="fas fa-angle-left"
							next-icon="fas fa-angle-right"
						>
							<v-carousel-item v-for="(file, i) in Files" :key="i">
								<v-container class="pa-0" fluid>
									<v-row>
										<v-col class="d-flex align-center justify-center">
											<v-img
												style="max-width: 1000px; max-height: 600px"
												v-if="file.MediaTypeId === 1"
												contain
												height="100%"
												width="100%"
												:key="file.MediaId"
												:src="file.src"
											>
												<template v-slot:placeholder>
													<v-row
														class="fill-height ma-0"
														align="center"
														justify="center"
													>
														<v-progress-circular
															indeterminate
															color="grey lighten-2"
														></v-progress-circular>
													</v-row>
												</template>
											</v-img>
											<div v-if="file.MediaTypeId === 2">
												<vueVimeoPlayer
													class="vimeo"
													:video-url="file.ExternalMediaUrl"
												>
												</vueVimeoPlayer>
											</div>
										</v-col>
									</v-row>
								</v-container>
							</v-carousel-item>
						</v-carousel>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-dialog>
</template>
<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import ApproveStatusIcon from "./ApproveStatusIcon";
import { SgpApprovalOfferItemStatusEnum } from "@/views/Events/components/sgpApprovalOfferItemStatus";

export default {
	name: "OfferGalleryDialog",
	components: {
		vueVimeoPlayer,
		ApproveStatusIcon,
	},
	props: {
		value: { type: Boolean, required: true },
		Files: { type: Array, required: true },
		carouselCurrentIndex: { type: Number },
		OfferKey: { type: String },
	},
	data() {
		return {
			sendingRequest: false,
			showRetouchingDialog: false,
			retouchingNotes: null,
			actionsCardSectionHeight: 0,
		};
	},

	watch: {
		carouselCurrentIndex: function (val) {
			this.CalculateActionsCardSectionHeight();
		},
		value: async function (val) {
			this.$log.debug("watch OfferGalleryDialog value");
			this.$log.debug(val);

			if (val) {
				this.$nextTick(() => {
					this.CalculateActionsCardSectionHeight();
				});
			}

			// if (!val) this.ClearNewUserDialogForm();
		},
	},
	created() {
		this.SgpApprovalOfferItemStatusEnum = SgpApprovalOfferItemStatusEnum;
		this.OffersService = new CrudClient("SGP/ApproverOffers");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		CalculateActionsCardSectionHeight() {
			this.$log.debug("CalculateActionsCardSectionHeight");

			this.actionsCardSectionHeight = this.$refs.actionsCardSection
				? this.$refs.actionsCardSection.$el.clientHeight
				: 0;

			this.$log.debug(
				"CalculateActionsCardSectionHeight:" + this.actionsCardSectionHeight
			);
		},

		HideImageDialog() {
			this.$emit("input", false);
		},

		OnCarouselCurrentIndex(param) {
			this.$emit("carousel-current-index-changed", param);

			//
		},
		carouselResize() {
			this.$log.debug("carouselResize");
		},
		GetApprovalStatusText(status) {
			// "Approved": "Approvato",
			// 	"Rejected": "Rifiutato",
			// 	"RetouchingRequested": "Ritocco Richiesto",
			// 	"PleaseApprove": "Si prega di approvare la foto"
			switch (status) {
				case SgpApprovalOfferItemStatusEnum.APPROVED:
					return this.$t("Approved");
				case SgpApprovalOfferItemStatusEnum.REJECTED:
					return this.$t("Rejected");
				case SgpApprovalOfferItemStatusEnum.RETOUCHING_REQUESTED:
					return this.$t("RetouchingRequested");
				case SgpApprovalOfferItemStatusEnum.NOT_SET:
				default:
					return this.$t("PleaseApprove");
			}
		},
		async OnAcceptClick(file) {
			this.sendingRequest = true;

			try {
				await this.OffersService.Post(
					this.OfferKey + "/" + file.MediaId,
					null,
					"Approve",
					false
				);

				const itemCount = 1;
				this.snackSuccess({
					Text: this.$tc("successAccept", itemCount, { count: itemCount }),
				});
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			} finally {
				this.sendingRequest = false;
			}

			this.$emit("approveStatusUpdated");
		},

		async OnRejectClick(file) {
			this.sendingRequest = true;

			try {
				await this.OffersService.Post(
					this.OfferKey + "/" + file.MediaId,
					null,
					"Reject",
					false
				);

				const itemCount = 1;
				this.snackSuccess({
					Text: this.$tc("successReject", itemCount, { count: itemCount }),
				});
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			} finally {
				this.sendingRequest = false;
			}

			this.$emit("approveStatusUpdated");
		},
		ShowRetouchingNotes(file) {
			this.showRetouchingDialog = true;
			this.retouchingNotes = file.SgpApprovalOfferItem.RetouchingNotes;
		},
		async SendRetouchingRequest(file) {
			if (!this.retouchingNotes || this.retouchingNotes.trim() === "") {
				this.snackError({ Text: "Please describe the modifications" });
				return;
			}
			this.sendingRequest = true;

			try {
				await this.OffersService.Post(
					this.OfferKey + "/" + file.MediaId,
					{ Notes: this.retouchingNotes },
					"RequestRetouching",
					false
				);

				const itemCount = 1;
				this.snackSuccess({
					Text: this.$tc("successRetouchingRequest", itemCount, {
						count: itemCount,
					}),
				});

				this.showRetouchingDialog = false;
				this.retouchingNotes = null;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			} finally {
				this.sendingRequest = false;
			}

			this.$emit("approveStatusUpdated");
		},
	},
};
</script>
<style lang="scss">
.vimeo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
<style lang="scss" scoped>
.containerF {
	display: flex;
	flex-direction: column;
	height: 100vh; /* Full viewport height */
}
.containerF div {
}
.div1,
.div2 {
	flex: 0 1 auto; /* These divs will take only the space they need */
}
.div3 {
	flex: 1 1 auto; /* This div will expand to fill the remaining space */
}
</style>

<i18n>
{
	"it": {

		"successAccept":"Hai approvato {count} foto | Hai approvato {count} foto",
		"successReject": "Hai rifiutato {count} foto | Hai rifiutato {count} foto",
		"successRetouchingRequest": "La richiesta di ritocco per {count} foto è stata inviata | La richiesta di ritocco per {count} foto è stata inviata",
		"Approve": "Approva",
		"Reject": "Rifiuta",
		"RequestRetouching": "Richiedi ritocco",
		"Approved": "Approvato",
		"Rejected": "Rifiutato",
		"RetouchingRequested": "Ritocco Richiesto",
		"PleaseApprove": "Si prega di approvare la foto"

	},
	"en": {

		"successAccept":"You have approved {count} photo | You have approved {count} photos",
		"successReject": "You have rejected {count} photo | You have rejected {count} photos",
		"successRetouchingRequest": "Retouching request for {count} photo is sent | Retouching request for {count} photos is sent",
		"Approve": "Approve",
		"Reject": "Reject",
		"RequestRetouching": "Request retouching",
		"Approved": "Approved",
		"Rejected": "Rejected",
		"RetouchingRequested": "Retouching Requested",
		"PleaseApprove": 	"Please approve photo"
	}
}
</i18n>
